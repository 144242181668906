<template>
  <footer class="footer">
  

    <nav class="social">
      <h1>Footer</h1>
    </nav>
  </footer>
</template>

<script setup>
import { useSite } from "~/composables";

const site = useSite();
</script>

<style scoped>

</style>
