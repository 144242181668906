<template>
  <div class="linksContainer">
    <div class="siteTitle">
      <a :href="baseURL + currentCode">
        {{ site.title }}
      </a>
    </div>
    <div class="navLinks">
      <router-link
        v-for="(page, i) in site.children.filter((page) => page.isListed)"
        :key="page.uri"
        :to="i == 0 ? '/' : `/${page.uri}`"
        :class="{
          open: route.path.startsWith(`/${page.uri}`),
          link: true,
        }"
      >
        {{ page.title }}
      </router-link>
    </div>

    <template v-for="lang in languages" :key="lang.code">
      <a :href="baseURL + `${lang.code}${route.path}`" class="lang">
        {{ lang.code.toUpperCase() }}
      </a>
    </template>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { useSite, useLanguages } from "~/composables";
export default {
  emits: ["showIntro"],
  setup() {
    const site = useSite();
    const route = useRoute();
    const { languageCode: currentCode } = useLanguages();
    const languages = [];
    for (const l in site.languages) {
      if (site.languages[l].code !== currentCode) {
        languages.push(site.languages[l]);
      }
    }

    var baseurl = import.meta.env.BASE_URL.slice(0, -5);
    if (baseurl.includes("http")) {
      var s = baseurl.split("/");
      s.splice(0, 3);
      baseurl = s.join("/");
    }
    // if (baseurl == "") {
    baseurl = baseurl;
    // }
    return {
      route,
      languages,
      site,
      currentCode,
      baseURL: baseurl,
    };
  },
};
</script>

<style lang="scss">
.siteTitle {
  height: 2.6em;
}

.linksContainer {
  padding: 2em;
  color: white;

  a {
    text-decoration: none;
    color: white;
  }

  .navLinks {
    margin-bottom: 1.3em;

    a.router-link-active,
    a.open {
      &:nth-child(4n + 1) {
        color: #00ff00;
      }

      &:nth-child(4n + 2) {
        color: #ff0000;
      }

      &:nth-child(4n + 3) {
        color: #ffff00;
      }

      &:nth-child(4n + 4) {
        color: #0000ff;
      }
    }
  }

  .link {
    display: block;
  }

  .lang {
    display: inline-block;
    margin-right: 1em;
  }
}

@media screen and (max-width: 800px) {
  .siteTitle {
    display: none;
  }

  .linksContainer {
    text-align: left;

    padding: 27px;

    a {
      height: 2em;
    }
  }
}
</style>
