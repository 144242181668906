<template>
  <div v-if="page.isReady">
    <div class="bigText" v-html="page.text" />
    <!-- <Sponsors v-if="!~route.path.indexOf('editorial')" /> -->
  </div>
  <!-- <Intro>{{ page.title }}</Intro> -->

  <!-- eslint-disable-next-line vue/no-v-html -->
  <!-- <div v-if="page.isReady" v-kirbytext class="text" v-html="page.text" /> -->
</template>

<script setup>
import { usePage } from "~/composables";

const page = usePage();
</script>

<style lang="scss">
.text {
  max-width: 35rem;
  margin: 0 auto;
}

.bigText {
  p {
    color: #ffff33;

    &:first-of-type {
      margin-top: 0px;
    }

    &:last-of-type {
      margin-bottom: 0px;
    }
  }

  a {
    color: #ffff33;
    text-decoration-thickness: 1px;
    text-underline-offset: 0.1em;
  }

  :visited {
    color: #ffff33;
  }
}
</style>
