<template>
  <div
    id="sideBarContainer"
    @mouseleave="setVisible(false)"
    :style="sidebar"
    :class="{ visible }"
  >
    <div id="main">
      <div id="columnContainer">
        <Navigation @showIntro="$emit('showIntro')" />
        <ArtistList
          @open_sidebar="openSideBar"
          :trans="transAmount"
          ref="list"
          :program="program"
        />
      </div>
    </div>
    <div id="close" @click="setVisible(false)">&lt;Close&gt;</div>
  </div>
  <div id="triggerArea" @mouseenter="setVisible(true)" />
  <div
    id="menuButton"
    :class="{ visible }"
    @click="setVisible(true)"
    @mouseenter="setVisible(true)"
  >
    Menu
  </div>
</template>
<script>
function ease(x) {
  return -(Math.cos(Math.PI * x) - 1) / 2;
}
function a_ease(x) {
  return Math.acos(-2 * x + 1) / Math.PI;
}

import { usePage } from "~/composables";

import { useRoute } from "vue-router";

import { mapMutations, mapState } from "vuex";

export default {
  emits: ["showIntro", "program"],
  props: ["program"],
  data() {
    return {
      open: false,
      changeTime: 0,
      transLength: 800,
      frame: null,
      transAmount: 0,
      sideWidth: window.innerWidth / 2,
      visible: false,
      route: useRoute(),
    };
  },
  mounted() {
    // setTimeout(() => {
    //   console.log(this.program);
    // }, "1000");
    // this.frame = requestAnimationFrame(this.animate);
  },
  computed: {
    sidebar() {
      var w = this.linksWidth + this.artistsWidth;
      var diff = this.descWidth - this.artistsWidth;
      return {
        // transform: "translateX(" + ((- 180) * (this.transAmount)) + "px)"
        width: w + diff + "px",
      };
    },
    ...mapState(["onMobile"]),
  },
  methods: {
    // ...mapMutations(["setMobileOverlay"]),
    setVisible(v) {
      this.visible = v;
      //   this.setMobileOverlay(v);
    },
    // getReverseTime() {
    //   return (
    //     new Date().getTime() -
    //     this.transLength *
    //       a_ease(
    //         1 -
    //           ease(
    //             Math.min(1, (new Date().getTime() - this.changeTime) / this.transLength)
    //           )
    //       )
    //   );
    // },
    // activateArtist(n) {
    //   this.artistList.forEach((element) => {
    //     element.active = false;
    //   });
    //   this.artistList[n].active = true;
    // },
    openSideBar() {
      if (!this.open) {
        this.open = true;
        this.changeTime = this.getReverseTime();
      }
    },
    closeSidebar() {
      if (this.visible) {
        this.visible = false;
      }
    },
    // animate() {
    //   requestAnimationFrame(this.animate);
    //   this.transAmount = ease(
    //     Math.min((new Date().getTime() - this.changeTime) / this.transLength, 1)
    //   );
    //   if (!this.open) {
    //     this.transAmount = 1 - this.transAmount;
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
#sideBarContainer {
  height: 100%;
  width: 30%;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0px;
  right: 0px;
  transform: translateX(100%);
  z-index: 200;
  transition: transform 600ms;

  &.visible {
    transform: translateX(0);
  }
}

#triggerArea {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100px;
  height: 100%;
  pointer-events: none;
}

#main {
  color: white;
  height: 100%;
}

#columnContainer {
  display: grid;
  grid-template-columns: 135px 1fr;
  height: 100%;
  line-height: 1.4em;
  font-size: 12px;
}

#menuButton {
  font-family: BanditSerif;
  font-variation-settings: "wdth" 300;
  font-size: 50px;
  display: block;
  z-index: 100;
  position: fixed;
  right: 50px;
  top: 50px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #ffff33;
  text-shadow: 0px 0px 5px #ffffff;
  opacity: 1;
  transition: opacity 400ms;

  &.visible {
    opacity: 0;
  }
}

#close {
  display: none;
}

@media screen and (min-width: 800px) and (hover: hover) {
  #triggerArea {
    pointer-events: all;
  }
}

@media screen and (max-width: 800px) {
  #sideBarContainer {
    width: 100% !important;
    height: 100%;
    transform: translateY(-100%);

    &.visible {
      transform: translateY(0);
    }
  }

  #menuButton {
    display: block;
    z-index: 100;
    position: fixed;
    top: 19px;
    right: 38px;
    // transform: translateX(-50%);
    cursor: pointer;
    color: #00ff00;
    font-size: 20px;
  }

  #columnContainer {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    line-height: 20pxc;
  }

  #close {
    display: block;
    position: fixed;
    top: 27px;
    right: 27px;
    color: #00ff00;
    font-size: 20px;
    cursor: pointer;
  }
}
</style>
