<template>
  <div :class="'artistContainer ' + (active ? 'active' : '')" @click="open">
    <div class="number">{{ n }}</div>
    <div class="cont">
      <div class="name">
        <!-- <component
          :is="isTransitioning"
          :key="artist.uri"
          class="artistLink"
          :to="`/${artist.uri}`"
        > -->
        {{ artist.name }}
        <!-- </component> -->
        <!-- <span class="artistLink placeholder"> {{ artist.name }}f </span> -->
      </div>
      <!-- <div class="hider" :style="getHeight">
        <div class="hiddenContent" ref="hiddenContent">
           <ArtistInfo :artist="artist" /> 
          <div class="space">&nbsp;</div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { mapState } from "vuex";

export default {
  props: ["artist", "n", "isCurrent"],
  data() {
    return {
      // active: false,
      h: 0,
      route: useRoute(),
    };
  },
  methods: {},
  computed: {
    getHeight() {
      return {
        height: this.h + "px",
      };
    },
    active() {
      return this.route.fullPath == "/" + this.artist.uri;
    },
    isTransitioning() {
      if (this.transitioning) {
        return "span";
      } else {
        return "router-link";
      }
    },
    ...mapState(["transitioning"]),
  },
  watch: {
    active: {
      handler() {
        if (this.active) {
          this.$nextTick(() => {
            this.h = this.$refs.hiddenContent.getBoundingClientRect().height;
          });
        } else {
          this.h = 0;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.artistContainer {
  display: flex;
  gap: 5px;
  // width: var(--artistsWidth);

  .number {
    flex-basis: var(--numberWidth);
    flex-shrink: 0;
  }

  .cont {
    cursor: pointer;
    min-width: 0;
    flex-grow: 2;
  }

  .name {
    width: calc(var(--artistsWidth) - var(--numberWidth));
    // padding-right: var(--padding);
    position: relative;
    width: 100%;
    // height: 1.3em;

    .artistLink {
      position: absolute;
      transform: translateY(0px);
      transition: transform 400ms;

      &.placeholder {
        position: relative;
        visibility: hidden;
      }

      &:hover {
        transform: translateY(-3px);
      }
    }

    a {
      text-decoration: none;
      color: white;

      &.router-link-active {
        color: #00ff00;
      }
    }
  }

  span.router-link-active {
    color: #00ff00;
  }

  .hider {
    overflow-y: hidden;
    transition: height 400ms;
    width: calc(var(--descWidth) - var(--numberWidth));
    padding-right: var(--padding);
    box-sizing: border-box;
    cursor: default;
  }

  &.active {
    .name {
      color: #00ff00;
    }
  }
}

@media screen and (max-width: 800px) {
  .artistContainer {
    // height: 2em;
    margin-bottom: 1em;

    .hider {
      height: 0px !important;
      visibility: none;
    }
  }
}
</style>
