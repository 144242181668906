<template>
  <Intro ref="intro" />

  <Announcer />
  <!-- <Header /> -->
  <div id="siteContainer" :style="vars">
    <div id="mainContent">
      <Animation
        @openSidebar="opensidebar"
        @closeSidebar="closeSidebar"
        :program="program"
      />
      <!-- <RouterView :key="route.path"> </RouterView> -->
      <RouterView v-slot="{ Component }">
        <transition name="fade" @after-enter="onAfterEnter">
          <component
            :is="Component"
            :key="$route.fullPath"
            :class="{ contentDiv: true, onMobile }"
          />
        </transition>
      </RouterView>
    </div>
    <Sidebar ref="sidebar" @showIntro="showIntro" :program="program" />
  </div>

  <Footer />
</template>

<script setup>
import { useRoute } from "vue-router";
import { usePage } from "~/composables";
// import Animation from "./animation/Animation.vue";

const route = useRoute();
</script>

<script>
export default {
  data() {
    return {
      route: useRoute(),
      lazyload: null,
      program: usePage("program"),
    };
  },
  mounted() {
    // Log this.program when the component is mounted
    console.log(this.program);
  },
  methods: {
    // ...mapMutations(["setMobile", "setWidth", "nextBackground"]),
    // settt() {
    //   this.setWidth(window.innerWidth);
    //   this.setMobile(
    //     window
    //       .getComputedStyle(document.querySelector("#mobileTest"))
    //       .getPropertyValue("display") == "block"
    //   );
    // },
    opensidebar() {
      console.log("open from app.vue");
      this.$refs.sidebar.setVisible(true);
    },
    closeSidebar() {
      this.$refs.sidebar.setVisible(false);
    },
    showIntro() {
      this.$refs.intro.activateIntro();
    },
    // onAfterEnter() {
    //   if (!this.route.path.includes("program")) this.nextBackground();
    // },
  },
  computed: {
    // ...mapState(["onMobile"]),
    vars() {
      return {
        "--linksWidth": this.linksWidth + "px",
        "--artistsWidth": this.artistsWidth + "px",
        "--descWidth": this.descWidth + "px",
        "--navWidth": this.artistsWidth + this.linksWidth + "px",
        "--padding": this.navPadding,
        "--numberWidth": this.numberWidth + "px",
      };
    },
  },
};
</script>

<style lang="scss">
#siteContainer {
  height: 100%;
  width: 100%;
  position: relative;
  background: transparent linear-gradient(180deg, #000000 0%, #bfbfbf 100%) 0% 0%
    no-repeat padding-box;
}

#mainContent {
  font-family: BanditSerif;
  font-variation-settings: "wdth" 300;
  font-size: 65px;
  overflow-y: hidden;
  height: 100%;
  width: calc(100vw);
  box-sizing: border-box;
  position: relative;
  text-shadow: 0px 0px 5px #ffffff;
}

@media screen and (max-width: 800px) {
  #mainContent {
    width: 100vw;
  }
}

.contentDiv {
  position: absolute;
  width: 100%;
  padding: 58px 68px;
  box-sizing: border-box;
  opacity: 1;
  min-height: 100%;
  height: 100%;
  overflow-y: auto;

  &.fade-enter-active {
    transition: opacity 0.5s ease 0.5s;
  }

  &.fade-leave-active {
    transition: opacity 0.5s ease;
  }

  &.fade-enter-from,
  &.fade-leave-to {
    opacity: 0;
  }

  &.onMobile {
    opacity: 1 !important;
    transition: none !important;
  }
}

#mobileTest {
  display: block;
}

@media screen and (min-width: 800px) {
  #mobileTest {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .contentDiv {
    padding: 60px 27px 27px 27px;

    &.fade-enter-active {
      transition: opacity 0s;
    }

    &.fade-leave-active {
      transition: opacity 0s;
    }
  }
}
</style>
