<template>
  <div id="introContainer" :class="showIntro" v-if="!deleteIntro">
    <div class="padder">
      <div class="inpadder">
        <img
          :srcset="site.introImage.srcset"
          :src="site.introImage.src_low"
          @click="start"
          class="desktop"
        />
        <img
          :srcset="site.introImage_mobile.srcset"
          :src="site.introImage_mobile.src_low"
          @click="start"
          class="mobile"
        />
      </div>
    </div>
    <!-- <div class="loadingMessage"
         v-if="!loaded">
      &lt;{{ loadingMessage }}&gt;
    </div> -->
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import { useSite } from "~/composables";

import { mapMutations } from "vuex";

export default {
  data() {
    return {
      deleteIntro: false,
      hideIntro: false,
      router: useRouter(),
      route: useRoute(),
      site: useSite(),
      loaded: false,
      index: 0,
    };
  },
  mounted() {
    // console.log(site.introImage);
    this.loaded = true;
    // document
    //   .querySelector(".animationContainer")
    //   .addEventListener("finishedLoad", (e) => {
    //     // console.log(e.detail)
    //   });
    console.log(this.route);
    if (!this.currentRoute.includes("program") && this.currentRoute != "/") {
      console.log("Delete intro");
      this.deleteIntro = true;
      this.hideIntro = true;
    }
    setInterval(this.inter, 500);
  },
  computed: {
    showIntro() {
      var s = {
        hidden: this.hideIntro,
        loading: !this.loaded,
      };
      return s;
    },
    currentRoute() {
      console.log(this.router.currentRoute);
      return this.router.currentRoute.fullPath;
    },
    loadingMessage() {
      var msg = "Loading";
      for (var i = 0; i < this.index % 4; i++) {
        msg += ".";
      }
      return msg;
    },
  },
  methods: {
    start() {
      if (!this.hideIntro && this.loaded) {
        // const event = new CustomEvent("start");
        // document.querySelector(".animationContainer").dispatchEvent(event);
        this.hideIntro = true;
        setTimeout(() => {
          // this.deleteIntro = true;
        }, 800);
      }
    },
    // activateIntro() {
    //   this.deleteIntro = false;
    //   setTimeout(() => {
    //     this.hideIntro = false;
    //   }, 40);
    // },
    // inter() {
    //   this.index++;
    // },
    // ...mapMutations(["setIntroVisible"]),
  },
  // watch: {
  //   currentRoute: {
  //     handler() {
  //       if (!this.currentRoute.includes("program") && this.currentRoute != "/") {
  //         this.hideIntro = true;
  //         this.deleteIntro = true;
  //       }
  //     },
  //     immediate: true,
  //   },
  //   hideIntro: {
  //     handler() {
  //       this.setIntroVisible(!this.hideIntro);
  //     },
  //     immediate: true,
  //   },
  // },
};
</script>

<style lang="scss">
#introContainer {
  z-index: 2000;
  position: fixed;
  top: 0px;
  left: 0px;
  background: transparent linear-gradient(180deg, #000000 0%, #bfbfbf 100%) 0% 0%
    no-repeat padding-box;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 800ms ease-in, transform 0s;
  transform: none;

  &.loading {
    img {
      cursor: wait;
    }
  }

  &.hidden {
    transform: scale(0) rotate(360deg);
    opacity: 0;
    transition: all 800ms ease-in;
  }

  .padder {
    width: 100%;
    height: 100%;
    padding: 100px;
    box-sizing: border-box;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1);
    // max-width: calc(100% - 200px);
    // max-height: calc(100% - 200px);
    cursor: pointer;

    transition: transform 250ms;

    &:hover {
      transform: translate(-50%, -50%) scale(1.1);
    }

    &.desktop {
      display: block;
    }

    &.mobile {
      display: none;
    }
  }
}

.inpadder {
  width: 100%;
  height: 100%;
  position: relative;
}

.loadingMessage {
  position: absolute;
  left: 50%;
  bottom: 50px;
  transform: translateX(-50%);
}
</style>
