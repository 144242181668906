<template>
  <div class="artistsContainer">
    <ArtistShower
      v-for="(artist, i) in artistList"
      :key="artist.name"
      :n="i + 1"
      :artist="artist"
      @closeAll="openSide"
      ref="artist"
    />
  </div>
</template>

<script>
export default {
  props: ["trans", "program"],
  setup() {
    return {};
  },
  methods: {
    openSide() {
      this.$emit("open_sidebar");
      this.closeAll();
    },
    closeAll() {},
  },
  mounted() {},
  emits: ["open_sidebar"],
  computed: {
    artistList() {
      if (this.program.isReady) {
        return this.program.bands;
      }
    },
  },
};
</script>

<style lang="scss">
.artistsContainer {
  height: 100%;
  padding: 4.6em 0px 2em 0px;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
}

@media screen and (max-width: 800px) {
  .artistsContainer {
    padding: 0em 27px 27px 27px;
  }
}
</style>
